import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-697b66c6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "breadBox"
};
const _hoisted_2 = {
  key: 1
};
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup(__props) {
    const store = useStore();
    const state = computed(() => {
      // 使用计算属性来监听数据的变化
      return store.state;
    });
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

      const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_breadcrumb, {
        separator: "/"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).currentPath, (item, index) => {
          return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: index
          }, {
            default: _withCtx(() => [index === 0 ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0
            }, {
              default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(item)))]),
              _: 2
            }, 1024)) : _createCommentVNode("", true), index ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item), 1)) : _createCommentVNode("", true)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })]);
    };
  }

};