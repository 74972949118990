import Home from "@/views/index.vue";
import login from "@/views/login.vue";
import notpath from "@/views/lose.vue";
// 
import store from "../store";
console.log('打印store数据：----', store.state);
const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/",
    name: "home",
    component: Home,
    redirect: "/index",
    children: [
      // Echarts图表示例 和 导出表格
      {
        path: "/index",
        name: "index",
        meta: {
          group: "monitor",
          groupName: "首页",
          name: "数据概览",
          icon: "Histogram",
          info: "monitor/首页/数据概览",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/home/home.vue"),
      },
      // {
      //   path: "/excel",
      //   name: "excel",
      //   meta: {
      //     group: "monitor",
      //     groupName: "",
      //     name: "代理商订单",
      //     icon: "Printer",
      //     info: "monitor/首页/代理商订单",
      //     keepAlive: false,
      //   },
      //   component: () => import("@/views/page/home/excel.vue"),
      // },














      // 商城段
      // 会员端配置
      {
        path: "/sysconfigVip",
        name: "sysconfigVip",
        meta: {
          group: "Open",
          groupName: "配置管理",
          name: "商城全局配置",
          icon: "Sunset",
          info: "Open/配置管理/商城全局配置",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/manage/configVip.vue"),
      },
      {
        path: "/bonusconfig",
        name: "bonusconfig",
        meta: {
          group: "Open",
          groupName: "配置管理",
          name: "奖励配置",
          icon: "Trophy",
          info: "Open/配置管理/会员端奖励配置",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/manage/bonusconfig.vue"),
      },
      {
        path: "/kdiconfig",
        name: "kdiconfig",
        meta: {
          group: "Open",
          groupName: "配置管理",
          name: "通证发放配置",
          icon: "Coin",
          info: "Open/配置管理/会员端通证发放配置",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/manage/kdiconfig.vue"),
      },
      {
        path: "/identity",
        name: "identity",
        meta: {
          group: "Open",
          groupName: "配置管理",
          name: "晋升配置",
          icon: "Suitcase",
          info: "Open/配置管理/云店晋升配置",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/manage/identity.vue"),
      },
      // {
      //   path: "/pondconfig",
      //   name: "pondconfig",
      //   meta: {
      //     group: "Open",
      //     groupName: "配置管理",
      //     name: "池底配置",
      //     icon: "Suitcase",
      //     info: "Open/配置管理/池底配置",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/manage/pondconfig.vue"),
      // },


      // 商城端，会员管理
      {
        path: "/user",
        name: "user",
        meta: {
          group: "User",
          groupName: "会员管理",
          name: "会员列表",
          icon: "UserFilled",
          info: "User/会员管理/会员列表",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/user/user.vue"),
      },
      {
        path: "/partner",
        name: "partner",
        meta: {
          group: "User",
          groupName: "会员管理",
          name: "提货点列表",
          icon: "HomeFilled",
          info: "User/会员管理/提货点列表",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/user/partner.vue"),
      },
      {
        path: "/audit",
        name: "audit",
        meta: {
          group: "User",
          groupName: "会员管理",
          name: "开店记录",
          icon: "Checked",
          info: "User/会员管理/开店实名记录",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/user/identifyAudit.vue"),
      },
      {
        path: "/actconf",
        name: "actconf",
        meta: {
          group: "User",
          groupName: "会员管理",
          name: "会员优惠券",
          icon: "Ticket",
          info: "User/会员管理/会员优惠券",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/user/actconf.vue"),
      },
      // 资产管理
      {
        path: "/apply",
        name: "apply",
        meta: {
          group: "User",
          groupName: "资产管理",
          name: "提现审核",
          icon: "Management",
          info: "User/资产管理/提现审核",
          keepAlive: true,
          role: "admin"
        },
        component: () => import("@/views/page/wallet/apply.vue"),
      },
      // 会员商品管理
      {
        path: "/goodsVip",
        name: "goodsVip",
        meta: {
          group: "Present",
          groupName: "会员商品管理",
          name: "商品列表",
          icon: "Goods",
          info: "Box/商品管理/商品列表",
          keepAlive: false,
          role: "admin/kuguan"
        },
        component: () => import("@/views/page/goodsVip/list.vue"),
      },
      {
        path: "/addgoodsVip",
        name: "addgoodsVip",
        meta: {
          group: "Present",
          groupName: "会员商品管理",
          name: "新增商品",
          icon: "CirclePlus",
          info: "Box/商品管理/新增商品",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goodsVip/addgoods.vue"),
      },
      {
        path: "/typesVip",
        name: "typesVip",
        meta: {
          group: "Present",
          groupName: "会员商品管理",
          name: "专区管理",
          icon: "TurnOff",
          info: "Box/商品管理/专区管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goodsVip/types.vue"),
      },
      {
        path: "/classifyVip",
        name: "classifyVip",
        meta: {
          group: "Present",
          groupName: "会员商品管理",
          name: "类别管理",
          icon: "Notification",
          info: "Box/商品管理/类别管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goodsVip/classify.vue"),
      },
      {
        path: "/specVip",
        name: "specVip",
        meta: {
          group: "Present",
          groupName: "会员商品管理",
          name: "规格管理",
          icon: "Menu",
          info: "Box/商品管理/规格管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goodsVip/spec.vue"),
      },

      // {
      //   path: "/goodslable",
      //   name: "goodslable",
      //   meta: {
      //     group: "Box",
      //     groupName: "商品管理",
      //     name: "标签管理",
      //     icon: "Menu",
      //     info: "Box/商品管理/标签管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/goodsVip/lable.vue"),
      // },

      // {
      //   path: "/integral",
      //   name: "integral",
      //   meta: {
      //     group: "Box",
      //     groupName: "商品管理",
      //     name: "积分专区",
      //     icon: "Coin",
      //     info: "Box/商品管理/积分专区",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/goods/integral.vue"),
      // },
      // {
      //   path: "/brand",
      //   name: "brand",
      //   meta: {
      //     group: "Box",
      //     groupName: "商品管理",
      //     name: "品牌管理",
      //     icon: "Connection",
      //     info: "Box/商品管理/品牌管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/goods/brand.vue"),
      // },



      // 订单管理
      {
        path: "/order",
        name: "order",
        meta: {
          group: "Management",
          groupName: "商城订单",
          name: "订单列表",
          icon: "List",
          info: "Management/商城订单/订单列表",
          keepAlive: false,
          role: "admin/kuguan"
        },
        component: () => import("@/views/page/order/order.vue"),
      },
      // {
      //   path: "/orderexchange",
      //   name: "orderexchange",
      //   meta: {
      //     group: "Management",
      //     groupName: "商城订单",
      //     name: "兑换订单",
      //     icon: "Collection",
      //     info: "Management/商城订单/兑换订单",
      //     keepAlive: false,
      //     role: "admin/kuguan"
      //   },
      //   component: () => import("@/views/page/order/orderexchange.vue"),
      // },
      // {
      //   path: "/ordergoods",
      //   name: "ordergoods",
      //   meta: {
      //     group: "Management",
      //     groupName: "商城订单",
      //     name: "导出发货单",
      //     icon: "Collection",
      //     info: "Management/商城订单/导出发货单",
      //     keepAlive: false,
      //     role: "admin/kuguan"
      //   },
      //   component: () => import("@/views/page/order/ordergoods.vue"),
      // },
      // {
      //   path: "/afterorder",
      //   name: "afterorder",
      //   meta: {
      //     group: "Collection",
      //     groupName: "订单管理",
      //     name: "售后订单",
      //     icon: "DocumentDelete",
      //     info: "Collection/订单管理/售后订单",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/order/afterorder.vue"),
      // },


      // {
      //   path: "/agent",
      //   name: "agent",
      //   meta: {
      //     group: "User",
      //     groupName: "用户管理",
      //     name: "代理商",
      //     icon: "Avatar",
      //     info: "User/用户管理/代理商",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/user/agent.vue"),

      // },
      // 资产管理
      // {
      //   path: "/apply",
      //   name: "apply",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "提现审核",
      //     icon: "Management",
      //     info: "Wallet/资产管理/提现审核",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/apply.vue"),
      // },
      // {
      //   path: "/transfer",
      //   name: "transfer",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "转账记录",
      //     icon: "Switch",
      //     info: "Wallet/资产管理/转账记录",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/transfer.vue"),
      // },
      // {
      //   path: "/ruleconfig",
      //   name: "ruleconfig",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "规则配置",
      //     icon: "Operation",
      //     info: "Wallet/资产管理/规则配置",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/ruleconfig.vue"),
      // },
      // {
      //   path: "/output",
      //   name: "output",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "产出配置",
      //     icon: "Operation",
      //     info: "Wallet/资产管理/产出配置",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/output.vue"),
      // },
      // {
      //   path: "/payconfig",
      //   name: "payconfig",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "支付配置",
      //     icon: "Open",
      //     info: "Wallet/资产管理/支付配置",
      //     keepAlive: true,
      //   },
      //   component: () => impor@/views/page/wallet/ruleconfig.vuevue"),
      // },
      // {
      //   path: "/outputinfo",
      //   name: "outputinfo",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "分红记录",
      //     icon: "Odometer",
      //     info: "Wallet/资产管理/分红记录",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/outputInfo.vue"),
      // },
      // {
      //   path: "/actconf",
      //   name: "actconf",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "优惠券管理",
      //     icon: "CollectionTag",
      //     info: "Wallet/资产管理/优惠券管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/actconf.vue"),
      // },
      // {
      //   path: "/ruleintro",
      //   name: "ruleintro",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "商城设置",
      //     name: "规则说明",
      //     icon: "Document",
      //     info: "Wallet/资产管理/规则说明",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/ruleintro.vue"),
      // },
      // {
      //   path: "/earningslog",
      //   name: "earningslog",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "订单释放",
      //     icon: "Coin",
      //     info: "Wallet/资产管理/订单释放",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/earningsLog.vue"),
      // },

      // 文章管理
      // {
      //   path: "/article",
      //   name: "article",
      //   meta: {
      //     group: "Tickets",
      //     groupName: "文章管理",
      //     name: "资讯列表",
      //     icon: "Document",
      //     info: "Tickets/文章管理/资讯列表",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/article/article.vue"),
      // },
      // {
      //   path: "/articleclass",
      //   name: "articleclass",
      //   meta: {
      //     group: "Tickets",
      //     groupName: "文章管理",
      //     name: "文章分类",
      //     icon: "ScaleToOriginal",
      //     info: "Tickets/文章管理/文章分类",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/article/articleClass.vue"),
      // },
      // {
      //   path: "/notify",
      //   name: "notify",
      //   meta: {
      //     group: "Tickets",
      //     groupName: "文章管理",
      //     name: "通知管理",
      //     icon: "ChatLineSquare",
      //     info: "Tickets/文章管理/通知管理",
      //     keepAlive: true,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/article/notify.vue"),
      // },
      // {
      //   path: "/advertise",
      //   name: "advertise",
      //   meta: {
      //     group: "Notebook",
      //     groupName: "文章管理",
      //     name: "广告管理",
      //     icon: "Position",
      //     info: "Notebook/文章管理/广告管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/article/advertise.vue"),
      // },


      // 商城设置
      {
        path: "/shop",
        name: "shop",
        meta: {
          group: "Iphone",
          groupName: "商城设置",
          name: "首页设置",
          icon: "Shop",
          info: "Iphone/商城设置/首页设置",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/shop/shop.vue"),
      },
      // {
      //   path: "/service",
      //   name: "service",
      //   meta: {
      //     group: "Iphone",
      //     groupName: "商城设置",
      //     name: "服务信息",
      //     icon: "Headset",
      //     info: "Iphone/商城设置/服务信息",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/shop/service.vue"),
      // },
      {
        path: "/agreementVip",
        name: "agreementVip",
        meta: {
          group: "Iphone",
          groupName: "商城设置",
          name: "用户协议",
          icon: "DocumentCopy",
          info: "Iphone/商城设置/用户协议",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/shop/agreementVip.vue"),
      },
      {
        path: "/privacyVip",
        name: "privacyVip",
        meta: {
          group: "Iphone",
          groupName: "商城设置",
          name: "隐私协议",
          icon: "Document",
          info: "Iphone/商城设置/隐私协议",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/shop/privacyVip.vue"),
      },
      // {
      //   path: "/agency",
      //   name: "agency",
      //   meta: {
      //     group: "Iphone",
      //     groupName: "商城设置",
      //     name: "代理商协议",
      //     icon: "Notebook",
      //     info: "Iphone/商城设置/代理商协议",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/shop/agency.vue"),
      // },
      {
        path: "/intro",
        name: "intro",
        meta: {
          group: "Iphone",
          groupName: "商城设置",
          name: "商城介绍",
          icon: "Tickets",
          info: "Iphone/商城设置/商城介绍",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/shop/intro.vue"),
      },
      // {
      //   path: "/version",
      //   name: "version",
      //   meta: {
      //     group: "Iphone",
      //     groupName: "商城设置",
      //     name: "APP版本",
      //     icon: "Cellphone",
      //     info: "Iphone/商城设置/APP版本管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/shop/version.vue"),
      // },


      /*
        CC端商城
      */
      // // 配置管理
      // {
      //   path: "/rolelist",
      //   name: "rolelist",
      //   meta: {
      //     group: "Operation",
      //     groupName: "配置管理",
      //     name: "角色管理",
      //     icon: "PartlyCloudy",
      //     info: "Operation/配置管理/角色管理",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/manage/list.vue"),
      // },
      // {
      //   path: "/arealabel",
      //   name: "arealabel",
      //   meta: {
      //     group: "Operation",
      //     groupName: "配置管理",
      //     name: "区域管理",
      //     icon: "Pouring",
      //     info: "Operation/配置管理/区域管理",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/manage/arealabel.vue"),
      // },
      // {
      //   path: "/sysconfig",
      //   name: "sysconfig",
      //   meta: {
      //     group: "Operation",
      //     groupName: "配置管理",
      //     name: "CC全局配置",
      //     icon: "Sunset",
      //     info: "Operation/配置管理/CC全局配置",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/manage/config.vue"),
      // },
      // //
      // // 控制台，用户
      // {
      //   path: "/avatar",
      //   name: "avatar",
      //   meta: {
      //     group: "Avatar",
      //     groupName: "用户管理",
      //     name: "用户列表",
      //     icon: "UserFilled",
      //     info: "User/用户管理/用户列表",
      //     keepAlive: false,
      //     role: "admin/caiwu"
      //   },
      //   component: () => import("@/views/page/avatar/user.vue"),
      // },
      // {
      //   path: "/avatarapply",
      //   name: "avatarapply",
      //   meta: {
      //     group: "Avatar",
      //     groupName: "用户管理",
      //     name: "余额提现",
      //     icon: "Management",
      //     info: "Avatar/用户管理/余额提现",
      //     keepAlive: false,
      //     role: "admin/caiwu"
      //   },
      //   component: () => import("@/views/page/avatar/apply.vue"),
      // },
      // {
      //   path: "/integralapply",
      //   name: "integralapply",
      //   meta: {
      //     group: "Avatar",
      //     groupName: "用户管理",
      //     name: "积分提现",
      //     icon: "CreditCard",
      //     info: "Avatar/用户管理/积分提现",
      //     keepAlive: false,
      //     role: "admin/caiwu"
      //   },
      //   component: () => import("@/views/page/avatar/applyJF.vue"),
      // },
      // {
      //   path: "/subsidy",
      //   name: "subsidy",
      //   meta: {
      //     group: "Avatar",
      //     groupName: "用户管理",
      //     name: "门店补贴记录",
      //     icon: "CollectionTag",
      //     info: "User/用户管理/门店补贴记录",
      //     keepAlive: true,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/avatar/subsidy.vue"),
      // },
      // {
      //   path: "/blacklist",
      //   name: "blacklist",
      //   meta: {
      //     group: "Avatar",
      //     groupName: "用户管理",
      //     name: "门店黑名单",
      //     icon: "WarningFilled",
      //     info: "User/用户管理/门店黑名单",
      //     keepAlive: true,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/avatar/blacklist.vue"),
      // },
      // // {
      // //   path: "/avataraudit",
      // //   name: "avataraudit",
      // //   meta: {
      // //     group: "Avatar",
      // //     groupName: "用户管理",
      // //     name: "实名认证",
      // //     icon: "Checked",
      // //     info: "User/用户管理/实名认证",
      // //     keepAlive: true,
      // //   },
      // //   component: () => import("@/views/page/avatar/identifyAudit.vue"),
      // // },
      // // {
      // //   path: "/avataractconf",
      // //   name: "avataractconf",
      // //   meta: {
      // //     group: "Avatar",
      // //     groupName: "用户管理",
      // //     name: "用户优惠券",
      // //     icon: "CollectionTag",
      // //     info: "User/用户管理/用户优惠券",
      // //     keepAlive: true,
      // //   },
      // //   component: () => import("@/views/page/avatar/actconf.vue"),
      // // },


      // // 商品管理
      // {
      //   path: "/goods",
      //   name: "goods",
      //   meta: {
      //     group: "Box",
      //     groupName: "CC商品管理",
      //     name: "商品列表",
      //     icon: "Goods",
      //     info: "Box/商品管理/商品列表",
      //     keepAlive: false,
      //     role: "admin/kuguan"
      //   },
      //   component: () => import("@/views/page/goods/list.vue"),
      // },
      // {
      //   path: "/addgoods",
      //   name: "addgoods",
      //   meta: {
      //     group: "Box",
      //     groupName: "CC商品管理",
      //     name: "新增商品",
      //     icon: "CirclePlus",
      //     info: "Box/商品管理/新增商品",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/goods/addgoods.vue"),
      // },
      // {
      //   path: "/inventer",
      //   name: "inventer",
      //   meta: {
      //     group: "Box",
      //     groupName: "CC商品管理",
      //     name: "库存管理",
      //     icon: "MessageBox",
      //     info: "Box/商品管理/库存管理",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/goods/inventer.vue"),
      // },
      // {
      //   path: "/unit",
      //   name: "unit",
      //   meta: {
      //     group: "Box",
      //     groupName: "CC商品管理",
      //     name: "单位管理",
      //     icon: "Present",
      //     info: "Box/商品管理/单位管理",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/goods/unit.vue"),
      // },
      // {
      //   path: "/classify",
      //   name: "classify",
      //   meta: {
      //     group: "Box",
      //     groupName: "CC商品管理",
      //     name: "类别管理",
      //     icon: "Notification",
      //     info: "Box/商品管理/类别管理",
      //     keepAlive: true,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/goods/classify.vue"),
      // },
      // {
      //   path: "/spec",
      //   name: "spec",
      //   meta: {
      //     group: "Box",
      //     groupName: "CC商品管理",
      //     name: "规格管理",
      //     icon: "Menu",
      //     info: "Box/商品管理/规格管理",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/goods/spec.vue"),
      // },
      // {
      //   path: "/logistics",
      //   name: "logistics",
      //   meta: {
      //     group: "Box",
      //     groupName: "CC商品管理",
      //     name: "物流模板",
      //     icon: "Van",
      //     info: "Box/商品管理/物流模板",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/goods/logistics.vue"),
      // },
      // // 采购订单管理
      // {
      //   path: "/purchaseorder",
      //   name: "purchaseorder",
      //   meta: {
      //     group: "Notebook",
      //     groupName: "采购订单",
      //     name: "订单列表",
      //     icon: "List",
      //     info: "Notebook/采购订单/订单列表",
      //     keepAlive: false,
      //     role: "admin/caiwu/kuguan"
      //   },
      //   component: () => import("@/views/page/purchaseorder/order.vue"),
      // },
      // {
      //   path: "/purchaseordergoods",
      //   name: "purchaseordergoods",
      //   meta: {
      //     group: "Notebook",
      //     groupName: "采购订单",
      //     name: "导出发货单",
      //     icon: "Collection",
      //     info: "Notebook/采购订单/导出发货单",
      //     keepAlive: false,
      //     role: "admin/kuguan"
      //   },
      //   component: () => import("@/views/page/purchaseorder/ordergoods.vue"),
      // },

      // {
      //   path: "/agreement",
      //   name: "agreement",
      //   meta: {
      //     group: "Cellphone",
      //     groupName: "协议设置",
      //     name: "用户协议",
      //     icon: "DocumentCopy",
      //     info: "Cellphone/协议设置/用户协议",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/shop/agreement.vue"),
      // },
      // {
      //   path: "/privacy",
      //   name: "privacy",
      //   meta: {
      //     group: "Cellphone",
      //     groupName: "协议设置",
      //     name: "隐私协议",
      //     icon: "Document",
      //     info: "Cellphone/协议设置/隐私协议",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/shop/privacy.vue"),
      // },



      // 设置
      {
        path: "/setting",
        name: "setting",
        meta: {
          group: "",
          name: "设置",
          icon: "Setting",
          info: "setting/设置中心",
          keepAlive: false,
          role: "admin/caiwu"
        },
        component: () => import("@/views/page/setting/setting.vue"),
      },
    ],
  },
  {
    path: "/:pathMacth(.*)",
    component: notpath,
  },
];

export default routes;
