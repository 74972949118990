import axios from "axios";
import { ElMessage } from "element-plus";
import { delOssApi } from "@/utils/config.js";
// 导入vue实例
export const toGet = async function (url, data) {
  let rn = "GET" + randomNumber();
  let urlData = "";
  if (typeof data == "object") {
    for (let key in data) {
      urlData +=
        urlData === ""
          ? "?" + key + "=" + data[key]
          : "&" + key + "=" + data[key];
    }
    url = url + urlData;
  } else if (typeof data == "string" || typeof data == "number") {
    url = url + data;
  }
  urlData = url + urlData;
  console.log("发起请求，ID：", rn);
  return new Promise((resolve, reject) => {
    axios.get(urlData).then((response) => {
      console.log("------" + rn + "------");
      console.log("请求链接为：" + urlData);
      console.log("返回结果", response.data);
      console.log("------end------");
      //
      resolve(response.data);
    });
  });
};
export const toPost = async function (url, data, isJson = false) {
  let rn = "POST" + randomNumber();
  console.log("发起请求，ID：", rn, data);
  return new Promise((resolve, reject) => {
    if (isJson) {
      console.log(rn + "请求地址为：" + url);
      console.log("json上传");
      axios({
        url: url,
        data: data,
        method: "POST",
        header: {
          "Content-Type": "application/json", //如果写成contentType会报错
        },
      });
    } else {
      axios.post(url, data).then((response) => {
        console.log("------" + rn + "------");
        console.log("请求地址为：" + url);
        console.log("上传数据", data);
        console.log("返回结果", response.data);
        console.log("------end------");
        //
        resolve(response.data);
      });
    }
  });
};
// 删除oss图片  // 正式版地址 ljserve.jhyshop.com
export const delOssImg = function (path, url = delOssApi) {
  console.log("删除图片", path);
  toGet(url, path);
};
// 复制文本内容
export const toCopy = (text) => {
  if (window.clipboardData) {
    window.clipboardData.setData("text", text);
  } else {
    (function () {
      document.oncopy = function (e) {
        e.clipboardData.setData("text", text);
        e.preventDefault();
        document.oncopy = null;
      };
    })(text);
    document.execCommand("Copy", true);
  }
  ElMessage.success("复制成功");
};
// 获取4位随机数
export const randomNumber = function () {
  let number = Math.floor(Math.random() * (9999 - 1000)) + 1000;
  return number;
};
// 时间转字符串
export const dateToString = function (date) {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  let Hours = date.getHours().toString();
  let Minutes = date.getMinutes().toString();
  let Seconds = date.getSeconds().toString();
  if (month.length === 1) {
    month = "0" + month;
  }
  if (day.length === 1) {
    day = "0" + day;
  }
  if (Hours.length === 1) {
    Hours = "0" + Hours;
  }
  if (Minutes.length === 1) {
    Minutes = "0" + Minutes;
  }
  if (Seconds.length === 1) {
    Seconds = "0" + Seconds;
  }
  let dateTime =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    Hours +
    ":" +
    Minutes +
    ":" +
    Seconds;
  return dateTime;
};
// 字符串转时间
export const stringToDate = function (
  dateStr,
  separator = "-",
  separator2 = ":"
) {
  console.log("字符串" + dateStr);
  let dataArr = dateStr.split(" ");
  console.log(dataArr);
  let dateArr1 = dataArr[0].split(separator);
  let dateArr2 = dataArr[1].split(separator2);
  // 年月日
  let year = parseInt(dateArr1[0]);
  let mon;
  if (dateArr1[1].indexOf("0") === 0) {
    mon = parseInt(dateArr1[1].substring(1));
  } else {
    mon = parseInt(dateArr1[1]);
  }
  let day = parseInt(dateArr1[2]);
  // 时分秒
  let h = parseInt(dateArr2[0]);
  let m = parseInt(dateArr2[1]);
  let s = parseInt(dateArr2[2]);
  let date = new Date(year, mon - 1, day, h, m, s);
  return date;
};
// 清空对象
export const clearObj = function (obj, def = {}) {
  console.log("重置对象");
  console.log(def);
  for (let key in obj) {
    if (def[key] !== undefined) {
      obj[key] = def[key];
      continue;
    }
    switch (typeof obj[key]) {
      case "object":
        {
          if (typeof obj[key].length === "number") {
            obj[key] = [];
          } else {
            obj[key] = {};
          }
        }
        break;
      case "number":
        {
          obj[key] = 0;
        }
        break;
      case "boolean":
        {
          obj[key] = false;
        }
        break;
      case "string":
        {
          obj[key] = "";
        }
        break;
    }
  }
  return obj;
};

// 获取当前时间
export const time = function (type) {
  var time = new Date();
  var Year = time.getFullYear();
  var Month =
    time.getMonth() + 1 < 10
      ? "0" + (time.getMonth() + 1)
      : time.getMonth() + 1;
  var Day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
  var Hours = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
  var Minutes =
    time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
  var Seconds =
    time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
  if (type === "s") {
    return Hours + ":" + Minutes + ":" + Seconds;
  }
  if (type === "d") {
    return Year + "-" + Month + "-" + Day;
  }
  if (!type) {
    return (
      Year +
      "-" +
      Month +
      "-" +
      Day +
      " " +
      Hours +
      ":" +
      Minutes +
      ":" +
      Seconds
    );
  }
};
// 获取多少天后的时间
export const getDateStr = function (today, addDayCount) {
  console.log(today, addDayCount);
  let dd;
  if (today) {
    dd = new Date(today);
  } else {
    dd = new Date();
  }
  dd.setDate(dd.getDate() + parseInt(addDayCount)); // 获取AddDayCount天后的日期
  // console.log(dd)
  let y = dd.getFullYear();
  let m =
    dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
  let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
  return y + "-" + m + "-" + d;
};
// 查询两个时间相差天数
export const dateDiff = function (sDate1, sDate2) {
  console.log(sDate1, sDate2);
  let diff = Date.parse(sDate1) - Date.parse(sDate2);
  let iDays = diff / (1000 * 60 * 60 * 24);
  console.log(iDays);
  return iDays;
};
// 二维数组排序
/**
 * @description:降序 排序根据哪那个字段,适用于二维数组排序
 * @param {*}arr 要排序的数组
 * @param {*}key 需要排序的键名
 * @return {*}
 */
export const downSort = (arr = [], key) => {
  let tmp = JSON.parse(JSON.stringify(arr)); //深拷贝一份
  tmp.sort(function (a, b) {
    //判断是不是数字,数字则不处理,不是数字则转为对应Unicode码
    a = !isNaN(Number(a[key])) ? a[key] : a[key].charCodeAt(0);
    b = !isNaN(Number(b[key])) ? b[key] : b[key].charCodeAt(0);
    return a - b;
  });
  return tmp;
};
/**
 * @description: 升序 排序根据哪那个字段,适用于二维数组排序
 * @param {*}arr 要排序的数组
 * @param {*}key 需要排序的键名
 * @return {*}
 */
export const upSort = (arr = [], key) => {
  let tmp = JSON.parse(JSON.stringify(arr)); //深拷贝一份
  tmp.sort(function (a, b) {
    a = !isNaN(Number(a[key])) ? a[key] : a[key].charCodeAt(0);
    b = !isNaN(Number(b[key])) ? b[key] : b[key].charCodeAt(0);
    return b - a;
  });
  return tmp;
};

// 验证手机号
export const phoneFun = function (phones) {
  phones = phones + ''
  console.log(phones.length);
  let myreg =
    /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|16[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}|17[0-9]{1}[0-9]{8}$|19[0-9]{1}[0-9]{8}$|14[0-9]{1}[0-9]{8}/;
  if (!myreg.test(phones) || phones.length > 11) {
    // console.log('这不是手机！！！', phones)
    return false;
  } else {
    // console.log('这是手ji', phones)
    return true;
  }
};


// 上传图片前判断
export const beforeAvatarUpload = (rawFile) => {
  if (rawFile.type !== "image/jpeg" && rawFile.type !== "image/png" && rawFile.type !== "image/webp") {
    ElMessage.error("请上传PNG、JPG或WEBP格式的图片");
    return false;
  } else if (rawFile.size / 1024 / 1024 > 1) {
    ElMessage.error("图像大小应小于1MB!");
    return false;
  }
  return true;
};



// 判断是不是JSON
export const isJson = (str) => {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      // 等于这个条件说明就是JSON字符串 会返回true
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        //不是就返回false
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
}

// 打印数据
export const log = {
  "err": (v1 = '', v2 = '', v3 = '', v4 = '', v5 = '') => {
    if (logoff) return
    if (v1) console.error(v1)
    if (v2) console.error(v2)
    if (v3) console.error(v3)
    if (v4) console.error(v4)
    if (v5) console.error(v5)
  },
  "info": (v1 = '', v2 = '', v3 = '', v4 = '', v5 = '') => {
    if (logoff) return
    if (v1) console.info(v1)
    if (v2) console.info(v2)
    if (v3) console.info(v3)
    if (v4) console.info(v4)
    if (v5) console.info(v5)
  },
  "warn": (v1 = '', v2 = '', v3 = '', v4 = '', v5 = '') => {
    if (logoff) return
    if (v1) console.warn(v1)
    if (v2) console.warn(v2)
    if (v3) console.warn(v3)
    if (v4) console.warn(v4)
    if (v5) console.warn(v5)
  },
  "debug": (v1 = '', v2 = '', v3 = '', v4 = '', v5 = '') => {
    if (logoff) return
    if (v1) console.debug(v1)
    if (v2) console.debug(v2)
    if (v3) console.debug(v3)
    if (v4) console.debug(v4)
    if (v5) console.debug(v5)
  },
  "bg": (v1 = '', v2 = '', v3 = '', v4 = '', v5 = '') => {
    if (logoff) return
    if (v1) log.info('%c' + v1, 'color:#ffffff;background:#ff4200;border-radius: 5px;padding: 2px 5px;font-size:12px')
    if (v2) log.info('%c' + v2, 'color:#ffffff;background:#ff4200;border-radius: 5px;padding: 2px 5px;font-size:12px')
    if (v3) log.info('%c' + v3, 'color:#ffffff;background:#ff4200;border-radius: 5px;padding: 2px 5px;font-size:12px')
    if (v4) log.info('%c' + v4, 'color:#ffffff;background:#ff4200;border-radius: 5px;padding: 2px 5px;font-size:12px')
    if (v5) log.info('%c' + v5, 'color:#ffffff;background:#ff4200;border-radius: 5px;padding: 2px 5px;font-size:12px')
  }
}
