import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      menuActive: "index",
      currentPath: ["Monitor", "首页"],
      adminUserInfo: sessionStorage.getItem("adminUserInfo")
        ? JSON.parse(sessionStorage.getItem("adminUserInfo"))
        : {},
      token: "",
      pageInfos: {},
      msg: 0
    };
  },
  getters: {
    getAdmin(state) {
      return state.adminUserInfo;
    },
    Gtoken(state) {
      return state.token;
    },
    getPageInfos(state) {
      return state.pageInfos;
    },
    getMsg(state) {
      return state.msg;
    }
  },
  mutations: {
    setMsg(state, newVal) {
      state.msg = newVal;
    },
    setPage(state, newVal) {
      state.pageInfos = newVal;
      console.log(newVal);
    },
    changeBreadCrumb(state, newVal) {
      state.currentPath = newVal;
    },
    changeMenu(state, newVal) {
      state.menuActive = newVal;
    },
    loginToken(state, newVal) {
      state.token = newVal;
    },
    login(state, newVal) {
      let data = JSON.stringify(newVal);
      sessionStorage.setItem("adminUserInfo", data);
      console.log('登录的用户信息', newVal)
      state.adminUserInfo = newVal;
    },
  },
  actions: {},
  modules: {},
});
